.consumer-page {
  overflow: hidden;

  #contact-modal {
    backdrop-filter: blur(7px);
    background-color: rgba(0, 0, 0, 0.5);
    /* Optional: change the background color */
  }

  .main {
    min-height: 100vh;
  }

  .consumer-grid-table {
    .ag-cell {
      border: none !important;
      outline: none !important;
    }
  }

  /*For Dashboard 26-09-2024*/

  .consumer-page-view {
    width: 100%;
    height: 100%;
    background-color: #ecf1f8;
    display: flex;
    flex-direction: column;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-pills .nav-link.active,
  .nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 4px solid !important;
    font-size: 18px;
    font-weight: bold;
  }

  .nav-tabs .nav-link {
    border: none;
    box-shadow: none;
  }

  .ab-navbar {
    background-color: #fff;
    gap: 0 40px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ab-navbar .ab-logo img {
    max-width: 100%;
  }

  @media (max-width: 991px) {
    .ab-navbar {
      gap: 0 20px;
    }
  }

  @media (max-width: 767px) {
    .ab-navbar {
      gap: 0 10px;
    }

    .ab-navbar .ab-logo {
      width: 42px;
    }
  }

  .ab-navbar .ab-search {
    @media(min-width: 768px) {
      position: relative;
    }
  }

  .ab-navbar .form-control {
    padding: 15px 25px 15px 50px;
    background: #f4f9ff;
    border: 1px solid #e5eef7;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    .ab-navbar .form-control {
      padding: 12px 20px 12px 40px;
    }
  }

  .ab-navbar .ab-search i {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    font-size: 18px;
  }

  .ab-main {
    padding-top: 20px;
    /* padding-bottom: 20px; */
    overflow: hidden;
    display: flex;
    height: calc(100vh - 88px);
  }

  @media (max-width: 767px) {
    .ab-main {
      padding-top: 14px;
      padding-bottom: 20px;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .ab-main {
      padding-top: 14px;
      padding-bottom: 45px;
    }
  }

  .ab-card {
    --bs-card-border-color: #E3E9EF;
    box-shadow: 0px 3px 4px rgba(12, 12, 13, 0.03);
    --bs-card-spacer-y: 20px;
    --bs-card-spacer-x: 20px;
    --bs-card-border-radius: 16px;
  }

  .ab-card .ab-stock-icon {
    width: 100px;
    border: 1px solid #E3E9EF;
    border-radius: 8px;
  }

  .ab-card .ab-stock-icon>* {
    width: 90%;
    height: 90%;
    margin: auto;
    right: 0;
    bottom: 0;
  }

  .ab-card h6,
  .ab-card .h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #071437;
    margin: 0;
    padding: 0 0 15px;
  }

  .ab-card h2,
  .ab-card .h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    color: #071437;
  }

  .ab-card h3,
  .ab-card .h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    color: #071437;
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .ab-card .ab-calc-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 70px;
  }

  .ab-card .ab-calc-box input {
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    background-color: transparent;
    outline: none;
    display: inline-block;
    width: 100%;
    padding: 0;
    border: none;
    box-shadow: none;
    outline: none;
  }

  .ab-card .ab-calc-box input::-webkit-outer-spin-button,
  .ab-card .ab-calc-box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .ab-card .ab-stock-icon-ani {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 70px;
    border-radius: 50%;
    overflow: hidden;
  }

  .ab-status {
    display: flex;
    gap: 10px;
  }

  .ab-status label {
    font-size: 14px;
    font-weight: 500;
  }

  .ab-status .ab-status-pill {
    gap: 4px;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 10px;
    font-size: 12px;
    font-weight: 600;
  }

  .ab-status.success {
    color: #04B440;
  }

  .ab-status.success .ab-status-pill {
    background: rgba(4, 180, 64, 0.3);
    border: 1px solid rgba(4, 180, 64, 0.1);
    border-radius: 16px;
  }

  .ab-status.danger {
    color: #f8285a;
  }

  .ab-status.danger .ab-status-pill {
    background: rgba(248, 40, 90, 0.2);
    border: 1px solid rgba(248, 40, 90, 0.3);
    border-radius: 16px;
  }

  .ab-card-comp h6,
  .ab-card-comp .h6 {
    font-weight: 700;
    font-size: 18px;
  }

  .ab-chart-card {
    --bs-card-border-color: #E3E9EF;
    box-shadow: 0px 3px 4px rgba(12, 12, 13, 0.03);
    --bs-card-spacer-y: 20px;
    --bs-card-spacer-x: 20px;
    --bs-card-border-radius: 16px;
    --bs-card-cap-bg: transparent;
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1rem;

    .card-header {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      align-items: center;
      min-height: 55px;
    }
  }

  @media (min-width: 991px) {
    .ab-chart-card {
      height: 50%;
    }
  }

  @media (max-width: 767px) {
    .ab-chart-card {
      --bs-card-cap-padding-y: 0.8rem;
      --bs-card-cap-padding-x: 0.8rem;
      --bs-card-border-radius: 16px;
    }
  }

  .ab-chart-card .card-header h6,
  .ab-chart-card .card-header .h6 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #071437;
  }

  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 767px) {
    .ab-card {
      --bs-card-spacer-y: 12px;
      --bs-card-spacer-x: 12px;
      --bs-card-border-radius: 8px;
    }

    .ab-card .ab-stock-icon {
      width: 70px;
    }

    .ab-card .ab-stock-icon-ani {
      width: 50px;
    }

    .ab-card h2,
    .ab-card .h2 {
      font-size: 20px;
    }

    .ab-card h6,
    .ab-card .h6 {
      padding: 0 0 5px;
      font-size: 12px;
    }

    .ab-card .ab-calc-box {
      gap: 0;
    }

    .ab-card .ab-calc-box input {
      font-size: 22px;
      margin: 0 0 10px;
    }

    .ab-card h3,
    .ab-card .h3 {
      font-size: 22px;
      margin: 0 0 10px;
    }

    .ab-card .ab-status label {
      font-size: 13px;
    }

    .ab-card .ab-status .ab-status-pill {
      font-size: 13px;
    }
  }

  .ab-table .ab-table-head {
    background: #eee;
  }

  .ab-table .ab-table-head .row {
    padding: 8px 12px;
  }

  .ab-table .ab-table-body .row {
    padding: 8px 12px;
  }

  .ab-table .ab-table-body .row+.row {
    border-top: 1px solid #eee;
  }

  /* .ab-navbar {
  @media(max-width: 767px){
    position: relative;
  }
} */

  .ab-navbar .autoComplete-search {
    background: #fff;
    position: absolute;
    border-radius: 16px;
    box-shadow: 0px 3px 4px rgba(12, 12, 13, 0.03);
    width: 100%;
    z-index: 1;
    top: 100%;
    border: 1px solid #E3E9EF;
    padding: 10px;
    left: 0;

    @media(max-width: 767px) {
      border-radius: 0px 0px 16px 16px;
    }
  }

  .ab-navbar .autoComplete-search::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }

  .ab-navbar .autoComplete-search::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #adabab;
  }

  .ab-navbar .autoComplete-search::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  .ab-navbar .autoComplete-search .result-row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 11px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    background-color: #fff;
    transition: ease all 0.35s;
    border-radius: 10px;
  }

  .ab-navbar .autoComplete-search .result-row:hover {
    background-color: #ecf1f8;
  }

  .ab-navbar .autoComplete-search .s-img {
    position: relative;
    height: 40px;
    width: 40px;
  }

  .ab-navbar .autoComplete-search .s-img img {
    width: 100%;
  }

  .app-container>.container-fluid>.row {
    --bs-gutter-x: 0;
  }

  .ab-modal-cs {
    --bs-modal-width: 1440px;
  }

  .ab-modal-cs .modal-content {
    border: none;
  }

  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #13ad97;
    --bs-btn-border-color: #13ad97;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d9a8d;
    --bs-btn-hover-border-color: #0d9a8d;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d9a8d;
    --bs-btn-active-border-color: #0d9a8d;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d9a8d;
    --bs-btn-disabled-border-color: #0d9a8d;
  }

  .linechart-card-size-modal {
    height: 700px;
  }

  .linechart-card-size {
    height: 370px;
  }

  .linechart-card-size .linechart-card-size {
    min-height: 31vh;
  }

  @media(max-width: 767px) {
    .linechart-card-size-modal {
      height: 300px;
    }

    .linechart-card-size {
      height: 310px;
    }

    .linechart-card-size .linechart-card-size {
      height: 260px;
    }
  }

  .ab-chart-card .nav-tabs {
    border: none;
    gap: 8px;
  }

  .ab-chart-card .nav-tabs .nav-link {
    font-size: 13px;
    padding: 6px 16px;
    font-weight: 500;
    border: 1px solid #eee !important;
    border-radius: 20px !important;
    color: #13ad97;
  }

  .ab-chart-card .nav-tabs .nav-link.active {
    background-color: #13ad97;
    border-color: #13ad97;
    color: #fff;
  }

  .consumer-grid-table {
    width: 100%;
    display: flex;
    height: 100%;
  }

  .ag-root-wrapper.ag-layout-normal {
    border: none !important;
  }

  .ab-entry-popup .modal-header h5 {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .ab-entry-popup .modal-content {
    border: none;
    border-radius: 16px;
  }

  .ab-entry-popup .modal-header {
    padding: 20px;
  }

  .ab-entry-popup .modal-body {
    padding: 20px;
  }

  .ab-entry-popup .form-label {
    font-size: 14px;
    color: #555;
  }

  .ab-entry-popup .form-control {
    padding: 0.775rem 0.95rem 0.775rem 2.7rem;
    border: 1px solid #DBDFE9;
    box-shadow: none;
    color: #000;
    font-weight: 500;
  }

  .ab-entry-popup .form-control:focus {
    background: #F1F1F4;
  }

  .ab-entry-popup .btn {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.9rem;
  }

  .ab-entry-popup .position-relative i {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    color: #666;
  }

  .ab-entry-popup .form-switch {
    margin: 0;
  }

  .ab-entry-popup .form-switch .form-check-input {
    width: 3em;
    height: 1.6em;
  }

  .ab-entry-popup .form-switch .form-check-input:checked {
    background-color: #0F998E;
    border-color: #0F998E;
  }

  .ab-main .ag-root-wrapper {
    border: none;
  }

  .ab-main .ag-theme-alpin {
    height: 90% !important;
    width: 100%;
    background: transparent !important;
  }

  .ab-main .ag-header {
    border: none;
  }

  .ab-main .ag-header.ag-header-allow-overflow .ag-header-row {
    overflow: visible;
    background: #eee;
    font-weight: 600;
  }

  .ag-center-cols-viewport .ag-row-even .ag-cell {
    display: flex;
    align-items: flex-start;
  }

  .ag-center-cols-viewport .ag-cell-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: left;
  }

  .ab-main .ag-center-cols-container .ag-row {
    border-bottom: 1px solid #eee;
  }

  .ab-main .ag-center-cols-container .ag-row-even {
    border-bottom: 1px solid #eee;
  }

  .ab-main .ag-column-first .ag-cell-value>div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .download-app-container,
  .download-app-container-iphone {
    @media(min-width: 992px) {
      display: none !important;
    }

    bottom: 0;
    display: flex;
    left: 0;
    position: fixed;
    align-items: center;
    background-color: #fff;
    color: #fff;
    width: 100%;
    padding: 10px;
    box-shadow: 0 -0.125rem .25rem rgba(0, 0, 0, .075);

    .footer-logo {
      background: #000;
      padding: 7px;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      img {
        width: 100%;
      }
    }

    h4 {
      margin: 0;
      font-size: 15px;
      color: #000;
      font-weight: 600;
      padding: 0 9px;
    }

    .download-fixed-btn {
      background: #000;
      font-size: 12px;
      padding: 7px 16px;
      display: flex;
      margin-left: auto;
      border-radius: 30px;
      font-weight: 600;
    }
  }
}

@media (max-width: 767px) {
  .ag-center-cols-viewport .ag-cell-wrapper {
    font-size: 11px;
  }
  .ag-header.ag-header-allow-overflow .ag-header-row {
    font-size: 12px;
  }
  .ag-header-cell:not(.ag-header-cell-auto-height)
    .ag-header-cell-comp-wrapper {
    font-size: 12px;
  }
}
.ab-offcanvas{
  width: 16% !important;
}
