@import "//cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css";
@import "//cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css";
@import "//use.fontawesome.com/releases/v6.5.1/css/all.css";
@import "//fonts.googleapis.com/css2?family=Roboto&display=swap";
@import "//fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap";
@import "//fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --bs-body-font-family: 'Inter';
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.form-control {
      padding: 0.575rem 1.05rem;
}

.btn {
      --bs-btn-padding-y: 0.575rem;
}

.ag-theme-quartz,
.ag-theme-quartz-dark,
.ag-theme-quartz-auto-dark {
  --ag-font-family: 'Inter' !important;
}

body {
  display: contents;
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

.heading {
  display: inline-block;

  @media only screen and (max-device-width: 767px) {
    display: none;
  }
}

.navbar-nav button {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.overlay {
  background-color: #efefef;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.fa-edit {
  color: #198754 !important;
}

.fa-edit:hover {
  transform: scale(1.5);
}

.fa-trash {
  color: orangered !important;
}

.fa-trash:hover {
  transform: scale(1.5);
}

.positive {
  font-size: smaller;
  padding: 5px;
  border-radius: 10px;
  font-weight: bold;
  color: #198754;
  background-color: lightblue !important;
}

.negative {
  font-size: smaller;
  padding: 5px;
  border-radius: 10px;
  font-weight: bold;
  color: #f32424;
  background-color: #fad4d4 !important;
}

.css-1p3m7a8-multiValue {
  border: 0.5px solid;
  border-color: #cdd2d7;
  background-color: #fff !important;
  font-size: larger;
}

.alert-custom {
  float: right;
  width: fit-content;
  margin-bottom: -10px;
}

.fa-ban:hover {
  transform: scale(1.5);
}

.fa-check:hover {
  transform: scale(1.5);
}

.blocked-checkbox {
  background-color: #20ac91 !important;
  border-color: #20ac91 !important;
}

.delete-checkbox {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}

.beta-checkbox {
  background-color: #ff8e04 !important;
  border-color: #ff8e04 !important;
}

.row-selected {
  background-color: var(--ag-row-hover-color) !important;
}

.header-right {
  text-align: right;

  .ag-header-cell-label {
    display: flex;
    flex-direction: row-reverse;
  }
}

.nav-tabs .nav-item .nav-link {
  @media (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    @media (min-width: 1024px) {
      margin-left: 10px;
    }

    @media (max-width: 767px) {
      display: none !important;
    }
  }
}

.data-grid {
  height: 69vh;
}

.paging-panel {
  padding-top: 10px;
  align-items: baseline;

  @media only screen and (max-device-width: 767px) {
    display: block !important;
  }

  .pagination {
    .page-link:focus {
      background-color: var(--bs-pagination-disabled-bg);
    }

    @media only screen and (max-device-width: 767px) {
      margin-top: 1rem;
    }
  }
}

.donutchart {
  min-height: 70vh;
  text-align: center;
  overflow: hidden;

  .showLegends {
    position: absolute;
    width: 99%;
    font-size: 20px;

    @media only screen and (max-width: 767px) {
      bottom: 35px;
      flex-direction: column !important;
      font-size: 16px;
      text-align: left;
      margin-left: 25%;
    }
  }

  svg.chart {
    @media screen and (min-width: 474px) and (max-width: 767px) {
      transform: scale(2);
    }

    @media screen and (min-width: 425px) and (max-width: 473px) {
      transform: scale(3);
    }

    @media screen and (min-width: 400px) and (max-width: 424px) {
      transform: scale(4);
    }

    @media screen and (min-width: 280px) and (max-width: 399px) {
      transform: scale(5);
    }

    @media screen and (max-width: 279px) {
      transform: scale(8);
    }
  }
}

.custom-radio-container-call {
  &:checked {
    background-color: #198754;
    border-color: #198754;
    accent-color: #198754;
    box-shadow: none;
  }
}

.custom-radio-container-put {
  &:checked {
    background-color: #FF0000;
    border-color: #FF0000;
    accent-color: #FF0000;
    box-shadow: none;
  }
}

app-container:not(.consumer-page) {
  .form-check-input {
    &:focus {
      box-shadow: none;
    }

    background-color: blue;
    border-color: blue;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>") !important;
  }
}

.form-check-input:checked {
  background-color: green;
  border-color: green;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 4px solid !important;
  font-size: 18px;
  font-weight: bold;
}

.nav-tabs .nav-link {
  border: none;
  box-shadow: none;
}

.skeleton-loader {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.login-page .btn:hover{
  background-color: #d6dcdc;
  border-color: #fff;
  color: black;
}
